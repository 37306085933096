import React from "react";
import { Grid } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";

export default function TextItem({ text, itemData, upright }) {
  return (
    <>
      <Grid item>
        <WrapTypo lh="250%" ls="2px" fs="16px" xsfs="14px">
          {itemData}
          <span
            style={{
              textOrientation: "sideways",
              WebkitTextOrientation: "sideways",
            }}
          >
            ：
          </span>
          <span
            style={
              upright
                ? {
                    textOrientation: "upright",
                    WebkitTextOrientation: "upright",
                  }
                : {}
            }
          >
            {text}
          </span>
        </WrapTypo>
      </Grid>
    </>
  );
}
