import React from "react";
import Box from "@material-ui/core/Box";

export default function BuildImage({ src, n }) {
  return (
    <>
      <Box
        style={{
          maxWidth: '800px',
          width: "100%",
          height: "100%",
          margin: 'auto',
          lineHeight: '0',
          fontSize: '0'
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          src={src && src.url}
        />
      </Box>
    </>
  )

}