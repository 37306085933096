import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import "./index.css";
import "../style/font-style.css";
import Box from "@material-ui/core/Box";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import RecentNews from "../components/RecentNews";
import Profile from "../components/Profile";
import Media from "../components/Media";
import Content from "../components/content";
import Footer from "../components/footer";
import Pastevent from "../components/pastevent";
import { Grid, Hidden } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { Button } from "@material-ui/core";
import { navigate } from "gatsby";
import ImageContent from "../components/imageContent";
import BuildImage from "../components/buildImage";
import TextItem from "../components/textItem";
import styled from "styled-components";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const fetchProductData = async () => {
  const fetchProduct = await fetch(
    "https://hayafuji.microcms.io/api/v1/works?limit=40&fields=id,topImage,title",
    { headers: { "X-API-KEY": "4c838704-f501-404a-b96b-23aabd25962f" } }
  );
  try {
    return await fetchProduct.json();
  } catch (e) { }
};

const fetchBuildingData = async roomId => {
  const fetchProduct = await fetch(
    "https://hayafuji.microcms.io/api/v1/works/" + roomId,
    { headers: { "X-API-KEY": "4c838704-f501-404a-b96b-23aabd25962f" } }
  );
  try {
    return await fetchProduct.json();
  } catch (e) { }
};

const HoverChangeColorWhite = styled(Box)`
  :hover {
    filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg)
      brightness(107%) contrast(101%);
  }
`;

const HoverChangeColorGrey = styled(Box)`
  :hover {
    filter: invert(30%) sepia(0%) saturate(11%) hue-rotate(143deg)
      brightness(101%) contrast(93%);
  }
`;

export default function Building({ location }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [artList, setArtList] = useState([]);
  const productArray = [];
  const [choiceArt, setChoiceArt] = useState(-1);
  useEffect(() => {
    fetchProductData().then(data => {
      data &&
        data.contents &&
        data.contents.map(c => {
          productArray.push(c);
        });
      setArtList([...productArray]);
    });
  }, [setArtList]);

  const parse = url => {
    const urlSplit = url.split("?");
    return urlSplit.length > 0
      ? [...new URLSearchParams(urlSplit[1]).entries()].reduce(
        (obj, e) => ({ ...obj, [e[0]]: e[1] }),
        {}
      )
      : "";
  };

  const [roomId, setRoomId] = useState(
    (location.state && location.state.roomId) ||
    (parse(typeof window !== `undefined` ? window.location.href : "") &&
      parse(typeof window !== `undefined` ? window.location.href : "").room)
  );

  const [build, setBuild] = useState([]);
  useEffect(() => {
    fetchBuildingData(roomId).then(data => {
      console.log(data)
      if (data == undefined || data.topImage == undefined) {
        navigate(`/404`)
      } else {
        setBuild(data);
      }
    });
  }, [roomId, setBuild]);



  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom"
    });
  }

  const data = useStaticQuery(graphql`
    query {
      close: file(relativePath: { eq: "buildClose.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#ffffff" position="relative" id="top">
        <Box
          width="100%"
          zIndex="50"
          position="absolute"
          top="0"
          left="0"
          style={isXs ? { paddingTop: "24px" } : { paddingTop: "54px" }}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <HoverChangeColorWhite width="20px">
                <Link to="/">
                  <Img fluid={data.close.childImageSharp.fluid} />
                </Link>
              </HoverChangeColorWhite>
            </Grid>
          </Grid>
        </Box>
        <Box width="100%">
          <Box data-aos="zoom-in" data-aos-duration="1500">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                margin: 0,
                padding: 0,
                display: "block"
              }}
              src={build.topImage && build.topImage.url}
            />
          </Box>
          <Hidden xsDown>
            <Box
              data-aos="fade-up"
              data-aos-duration="3000"
              width="80%"
              margin="auto"
              py={7}
              style={{
                WebkitWritingMode: "vertical-rl",
                msWritingMode: "tb-rl",
                writingMode: "vertical-rl"
              }}
            >
              <Grid container direction="column" justify="center">
                <Grid item xs={1}>
                  <Box my={10} height="300px">
                    <WrapTypo
                      fs="1.2rem"
                      color="#000"
                      ls="10px"
                      style={{
                        textOrientation: "upright",
                        WebkitTextOrientation: "upright",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {build.title}
                    </WrapTypo>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box height="300px" my={10}>
                    <WrapTypo lh="250%" style={{ whiteSpace: "pre-wrap" }}>
                      {build.description}
                    </WrapTypo>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box
              width="90%"
              margin="48px auto"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <Grid container direction="column" justify="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "0 0 30px"
                  }}
                >
                  <WrapTypo
                    fs="1.2rem"
                    color="#000"
                    style={{
                      textOrientation: "upright",
                      WebkitTextOrientation: "upright"
                    }}
                  >
                    {build.title}
                  </WrapTypo>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <WrapTypo lh="250%" ls="1px" fs="14px">
                      {build.description}
                    </WrapTypo>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Box data-aos="fade-up">
            <BuildImage src={build.image1} />
            <BuildImage src={build.image2} />
            <BuildImage src={build.image3} />
            <BuildImage src={build.image4} />
            <BuildImage src={build.image5} />
            <BuildImage src={build.image6} />
          </Box>
          <Hidden xsDown>
            <Box
              data-aos="fade-up"
              data-aos-duration="3000"
              width="80%"
              margin="auto"
              py={7}
              style={{
                WebkitWritingMode: "vertical-rl",
                msWritingMode: "tb-rl",
                writingMode: "vertical-rl"
              }}
            >
              <Grid
                container
                direction="column"
                justify="space-around"
                style={{ margin: "40px 0 80px" }}
              >
                <Grid item xs={3}>
                  <TextItem text={build.usage} itemData="用途" />
                  <TextItem text={build.location} itemData="所在" />
                  <TextItem text={build.completion} itemData="竣工" />
                  <TextItem text={build.structure} itemData="構造" />
                  <TextItem
                    text={build.floorNum}
                    itemData="階数"
                    upright={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextItem text={build.siteArea} itemData="敷地面積" />
                  <TextItem text={build.buildingArea} itemData="敷地面積" />
                  <TextItem text={build.floorSpace} itemData="延床面積：" />
                  <TextItem text={build.design} itemData="設計" />
                  <TextItem text={build.construction} itemData="構造" />
                  <TextItem text={build.construction} itemData="施工" />
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box
              width="90%"
              margin="20px auto"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <Grid container direction="column" justify="center">
                <Grid item xs={12} style={{ margin: "0 0 60px" }}>
                  <TextItem text={build.usage} itemData="用途" />
                  <TextItem text={build.location} itemData="所在" />
                  <TextItem text={build.completion} itemData="竣工" />
                  <TextItem text={build.structure} itemData="構造" />
                  <TextItem
                    text={build.floorNum}
                    itemData="階数"
                    upright={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextItem text={build.siteArea} itemData="敷地面積" />
                  <TextItem text={build.buildingArea} itemData="敷地面積" />
                  <TextItem text={build.floorSpace} itemData="延床面積" />
                  <TextItem text={build.design} itemData="設計" />
                  <TextItem text={build.construction} itemData="構造" />
                  <TextItem text={build.construction} itemData="施工" />
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Box
            data-aos="fade-up"
            data-aos-duration="1500"
            margin="100px auto 0"
            style={{
              WebkitWritingMode: "vertical-rl",
              msWritingMode: "tb-rl",
              writingMode: "vertical-rl"
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <WrapTypo>他の新築工事</WrapTypo>
            </Grid>
          </Box>
          <ImageContent artList={artList} />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
